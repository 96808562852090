<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h2 class="title_section">
            Información
          </h2>
        </div>
        <div class="column is-12">
          <h2 class="subtitle text-divider">Información del Servicio</h2>
        </div>
        <div class="column is-3">
          <label class="label">Referencia</label>
          <template>
            <p>
              {{ reference }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Cliente</label>
          <template>
            <p>
              {{ clientSelect }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Servicio</label>
          <template>
            <p>
              {{ serviceSelect }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">No. PO</label>
          <template>
            <p>
              {{ numberPO }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Proveedor</label>
          <template>
            <p>
              {{ vendorSelect }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">No. Contenedor</label>
          <template>
            <p>
              {{ numberContainer }}
            </p>
          </template>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h2 class="subtitle text-divider">Información Operativa </h2>
        </div>
        <div class="column is-3">
          <label class="label">Ejecutivo Comercial</label>
          <template>
            <p>
              {{ commercialExecutiveSelect }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Coordinador Operativo MX</label>
          <template>
            <p>
              {{ cnOperationalCoordinator }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Fecha de Inspección</label>
          <template>
            <p>
              {{ formatDate(inspectionDate, 'DD MMMM YYYY') }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Fecha de Entrega</label>
          <template>
            <p>
              {{ formatDate(dateOfDelivery, 'DD MMMM YYYY') }}
            </p>
          </template>
        </div>
        <div class="column is-3">
          <label class="label">Mes</label>
          <template>
            <p>
              {{ month }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import mixins from '../../mixins/formats'

export default {
  name: 'ServiceInformation',
  mixins: [mixins],
  data () {
    return {
      serviceId: this.$route.params.serviceId,
      customerId: this.$route.params.customerId || null,
      reference: null,
      clientSelect: null,
      serviceSelect: null,
      numberPO: null,
      vendorSelect: null,
      numberContainer: null,
      commercialExecutiveSelect: null,
      cnOperationalCoordinator: null,
      inspectionDate: null,
      dateOfDelivery: null,
      month: null
    }
  },
  methods: {
    ...mapActions(['getServiceById']),
    async getServiceDetail () {
      const { service } = await this.getServiceById({ serviceId: this.serviceId, customerId: this.customerId })
      if (service) {
        this.setLocalValues(service)
      }
      this.loading = false
    },
    setLocalValues (data) {
      this.reference = data.reference
      this.clientSelect = data.customerName
      this.serviceSelect = data.serviceName
      this.numberPO = data.po_number
      this.vendorSelect = data.providerName
      this.numberContainer = data.container_number
      this.commercialExecutiveSelect = data.commercialexecutivename
      this.cnOperationalCoordinator = data.cn_coordinator
      this.inspectionDate = new Date(data.inspection_date)
      this.dateOfDelivery = new Date(data.delivery_date)
      this.month = data.monthName
    }
  },
  beforeMount () {
    this.loading = true
    this.getServiceDetail()
  }
}
</script>

<style lang="scss" scoped>
  .title_section {
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #404254;
    padding-bottom: 1rem;
  }
  .input {
    font-size: 14px;
  }
  .field.is-grouped {
    display: flex;
    justify-content: flex-end;
  }
  h2, h3, label {
    color: #5d6990;
  }
  p {
    color: #404254;
  }
</style>
